import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import L, { LatLngLiteral } from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef, useState } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  useMapEvent,
} from "react-leaflet";
import Button from "../../components/button/button";
import Header from "../../components/header/header";
import { Icon } from "../../components/icons";
import Input from "../../components/input/input";
import { db } from "../../firebase";
import styles from "./map.module.scss";

type MarkerData = {
  id: string;
  name: string;
  color: string;
  position: LatLngLiteral;
};

const CenterMapOnPosition: React.FC<{ position: LatLngLiteral }> = ({
  position,
}) => {
  const map = useMap();
  useEffect(() => {
    if (position) {
      map.setView(position, map.getZoom());
    }
  }, [position, map]);

  return null;
};

const MapClickHandler: React.FC<{
  onClick: (latlng: LatLngLiteral) => void;
}> = ({ onClick }) => {
  useMapEvent("click", (event) => {
    const { latlng } = event;
    onClick(latlng);
  });

  return null;
};

const MapComponent: React.FC = () => {
  const [markers, setMarkers] = useState<MarkerData[]>([]);
  const [selectedPosition, setSelectedPosition] =
    useState<LatLngLiteral | null>(null);
  const [name, setName] = useState("");
  const [color, setColor] = useState("#000000");
  const [address, setAddress] = useState("");
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [userLocation, setUserLocation] = useState<LatLngLiteral | null>(null);
  const [mapPosition, setMapPosition] = useState<LatLngLiteral>({
    lat: 51.505,
    lng: -0.09,
  });
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isAddMode, setIsAddMode] = useState(false);
  const [isTouchMode, setIsTouchMode] = useState(false);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const fetchMarkers = async () => {
      const markerCollection = collection(db, "markers");
      const snapshot = await getDocs(markerCollection);
      const fetchedMarkers = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as Omit<MarkerData, "id">),
      }));
      setMarkers(fetchedMarkers);
    };

    fetchMarkers();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const userLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setUserLocation(userLocation);
        if (isFirstLoad) {
          setMapPosition(userLocation);
          setIsFirstLoad(false);
        }
      });
    }
  }, [isFirstLoad]);

  const handleAddressChange = async (address: string) => {
    setAddress(address);
    setError(null);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    if (address.length > 3) {
      debounceTimeout.current = setTimeout(async () => {
        setIsLoading(true);
        try {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
              address
            )}`
          );
          if (!response.ok) {
            throw new Error("Errore durante il caricamento degli indirizzi.");
          }
          const results = await response.json();
          setSuggestions(results);
        } catch (error) {
          setError("Impossibile caricare i risultati. Riprova più tardi.");
        } finally {
          setIsLoading(false);
        }
      }, 500); // 500ms delay
    } else {
      setSuggestions([]);
    }
  };

  const handleSelectSuggestion = (suggestion: any) => {
    setAddress(suggestion.display_name);
    setSelectedPosition({
      lat: parseFloat(suggestion.lat),
      lng: parseFloat(suggestion.lon),
    });
    setSuggestions([]);
  };

  const handleSaveMarker = async () => {
    if (selectedPosition && name && color) {
      const newMarker: Omit<MarkerData, "id"> = {
        name,
        color,
        position: selectedPosition,
      };

      const markerCollection = collection(db, "markers");
      const docRef = await addDoc(markerCollection, newMarker);
      const newMarkers = [...markers, { id: docRef.id, ...newMarker }];
      setMarkers(newMarkers);
      setName("");
      setColor("#000000");
      setSelectedPosition(null);
      setAddress("");
      setIsTouchMode(false);
      setIsAddMode(false);
    }
  };

  const handleMarkerClick = async (marker: MarkerData) => {
    const confirmed = window.confirm("Vuoi rimuovere questo marker?");
    if (confirmed) {
      await deleteDoc(doc(db, "markers", marker.id));
      setMarkers(markers.filter((m) => m.id !== marker.id));
    }
  };

  const handleOpenInGoogleMaps = (marker: MarkerData) => {
    const googleMapsUrl = `https://www.google.com/maps?q=${marker.position.lat},${marker.position.lng}`;
    window.open(googleMapsUrl, "_blank");
  };

  const handleDrag = (event: L.LeafletEvent) => {
    const { lat, lng } = event.target.getLatLng();
    setSelectedPosition({ lat, lng });
  };

  const handleTouchMode = () => {
    setIsTouchMode(!isTouchMode);
    setSelectedPosition(null);
    setAddress("");
    setSuggestions([]);
    setName("");
    setColor("#000000");
  };

  return (
    <div className={styles.mapContainer}>
      <Header>
        <div className="flex align-items-center">
          <h2>Luoghi</h2>
          {isAddMode ? (
            <Button onClick={() => setIsAddMode(false)} className="ml-auto">
              <Icon content="close" color="white" />
            </Button>
          ) : (
            <Button onClick={() => setIsAddMode(true)} className="ml-auto">
              <Icon content="plus" color="white" />
            </Button>
          )}
        </div>
        {isAddMode && (
          <div className={styles.addPointControls}>
            <p>
              Cerca un indirizzo, assegna un nome e scegli un colore per il
              punto. Puoi spostare il marker prima di salvarlo.
            </p>
            <p>
              Attiva la modalità di aggiunta per posizionare un punto
              direttamente sulla mappa senza cercare un indirizzo.
            </p>
            <div className={styles.searchContainer}>
              <Button
                onClick={handleTouchMode}
                className={styles.toggleAddModeButton}
              >
                {isTouchMode
                  ? "Modalità Tocco Disattivata"
                  : "Attiva Tocco Aggiunta"}
              </Button>
              {!isTouchMode && (
                <Input
                  type="text"
                  value={address}
                  onChange={(e) => handleAddressChange(e.target.value)}
                  placeholder="Cerca indirizzo"
                  className={styles.searchInput}
                />
              )}
              {error && <p className={styles.errorText}>{error}</p>}
              <div className={styles.suggestionsList}>
                {isLoading && (
                  <span className={styles.suggestionItem}>Caricamento...</span>
                )}
                {suggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    onClick={() => handleSelectSuggestion(suggestion)}
                    className={styles.suggestionItem}
                  >
                    {suggestion.display_name}
                  </div>
                ))}
              </div>
            </div>
            {selectedPosition && (
              <div className={styles.formContainer}>
                <Input
                  type="text"
                  placeholder="Nome del posto"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className={styles.inputField}
                />
                <Input
                  type="color"
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                  className={styles.colorPicker}
                />
                <Button
                  onClick={handleSaveMarker}
                  className={styles.saveButton}
                >
                  <Icon content="save" color="white" />
                </Button>
              </div>
            )}
          </div>
        )}
      </Header>
      <MapContainer
        style={{
          height: "100%",
          flex: "1",
          marginLeft: "-1rem",
          marginRight: "-1rem",
        }}
        center={mapPosition}
        zoom={13}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <CenterMapOnPosition
          position={selectedPosition || userLocation || mapPosition}
        />
        <MapClickHandler
          onClick={(latlng) => {
            if (isTouchMode) {
              setSelectedPosition({
                lat: latlng.lat,
                lng: latlng.lng,
              });
            }
          }}
        />
        {markers.map((marker) => (
          <Marker
            key={marker.id}
            position={marker.position}
            icon={L.divIcon({
              className: "custom-icon",
              html: `<div style="background-color: ${marker.color}; width: 20px; height: 20px; border-radius: 50%;"></div>`,
            })}
          >
            <Popup>
              <div>
                <h3>{marker.name}</h3>
                <div className="flex gap-1">
                  <Button
                    className={styles.saveButton}
                    onClick={() => handleOpenInGoogleMaps(marker)}
                  >
                    <Icon content="map" color="white" />
                  </Button>
                  <Button
                    className={styles.saveButton}
                    onClick={() => handleMarkerClick(marker)}
                  >
                    <Icon content="trash" color="white" />
                  </Button>
                </div>
              </div>
            </Popup>
          </Marker>
        ))}
        {selectedPosition && (
          <Marker
            position={selectedPosition}
            draggable={true}
            icon={L.divIcon({
              className: "custom-icon",
              html: `<div style="background-color: ${color}; width: 20px; height: 20px; border-radius: 50%;"></div>`,
            })}
            eventHandlers={{ dragend: handleDrag }}
          />
        )}
      </MapContainer>
    </div>
  );
};

export default MapComponent;
