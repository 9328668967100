import React, { CSSProperties } from "react";
import styles from "./spinner.module.scss";

type Props = {
  className?: string;
  size?: "xs" | "sm" | "md" | "xl";
  color?: "white" | "black";
  style?: CSSProperties;
};

const Spinner = (props: Props) => {
  const { className, size = "sm", color = "white", style } = props;

  const COLORS = {
    white: "#ffffff",
    black: "#000000",
  };

  const compressedClasses = `
    ${styles.spinner} 
    ${size ? styles[size] : ""} 
    ${className || ""}
`;

  return (
    <div className={compressedClasses} style={style}>
      <svg
        style={{ margin: "auto", display: "block" }}
        width="40px"
        height="40px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke={`${COLORS[color]}`}
          strokeWidth="2"
          r="40"
          strokeDasharray="188.49555921538757 64.83185307179586"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="0.6211180124223602s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          ></animateTransform>
        </circle>
      </svg>
    </div>
  );
};

export default Spinner;
