import React from "react";
import styles from "./button.module.scss";

interface ButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
  type?: "button" | "submit" | "reset";
  color?: "blue" | "red";
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  children,
  disabled = false,
  className,
  type = "button",
  color = "blue",
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`${styles.button} ${styles[color]} ${className}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
