// src/Register.tsx
import React, { useState } from "react";
import { auth, db } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  setDoc,
  doc,
  collection,
  getDocs,
  writeBatch,
} from "firebase/firestore";

const Register: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [user] = useState(auth);

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      // Crea un nuovo utente con Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Aggiungi l'utente a Firestore
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        name: name,
        createdAt: new Date().toISOString(),
        complaintCount: 0,
        complaints: [],
      });

      setEmail("");
      setPassword("");
      setName("");
      setError("");

      alert("Registrazione completata con successo!");
    } catch (error) {
      console.error("Errore durante la registrazione:", error);
      setError("Errore durante la registrazione. Per favore, riprova.");
    }
  };

  if (user.currentUser?.email !== "davide.palluzzi@mailinator.com") {
    return <>Non autorizzato</>;
  }
  const reset = async () => {
    try {
      const usersCollection = collection(db, "users");
      const positionsCollection = collection(db, "positions");
      const shoppingListCollection = collection(db, "shoppingList");

      // Recupera tutti i documenti dalla collezione `users`
      const usersSnapshot = await getDocs(usersCollection);
      // Recupera tutti i documenti dalla collezione `positions`
      const positionsSnapshot = await getDocs(positionsCollection);
      // Recupera tutti i documenti dalla collezione `shoppingList`
      const shoppingListSnapshot = await getDocs(shoppingListCollection);

      const batch = writeBatch(db);

      // Reset dei dati utente
      usersSnapshot.forEach((userDoc) => {
        const userRef = doc(db, "users", userDoc.id);
        batch.update(userRef, {
          complaintCount: 0,
          complaints: [],
        });
      });

      // Eliminazione di tutti i documenti dalla collezione `positions`
      positionsSnapshot.forEach((positionDoc) => {
        const positionRef = doc(db, "positions", positionDoc.id);
        batch.delete(positionRef);
      });

      // Eliminazione di tutti i documenti dalla collezione `shoppingList`
      shoppingListSnapshot.forEach((shoppingListDoc) => {
        const shoppingListRef = doc(db, "shoppingList", shoppingListDoc.id);
        batch.delete(shoppingListRef);
      });

      // Completamento del batch
      await batch.commit();

      alert("Reset completato con successo!");
    } catch (error) {
      console.error("Errore durante il reset:", error);
      setError("Errore durante il reset. Per favore, riprova.");
    }
  };

  return (
    <div>
      <h2>Registrazione</h2>
      <form onSubmit={handleRegister}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Nome"
          required
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <button type="submit">Registrati</button>
        {error && <p>{error}</p>}
      </form>

      <div className="mt-1">
        <button onClick={reset}>RESETTA IL DATABASE</button>
      </div>
    </div>
  );
};

export default Register;
