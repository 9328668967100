import React, { useState, useCallback, useEffect } from "react";
import QRCode from "qrcode.react";
import { arrayUnion, doc, increment, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";
import QrReader from "react-qr-scanner";
import styles from "./homepage.module.scss";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../components/icons";
import Modal from "../../components/modal/modal";
import Button from "../../components/button/button";
import Spinner from "../../components/spinner/spinner";

const Homepage: React.FC = () => {
  const [isQRCodeVisible, setQRCodeVisible] = useState(false);
  const [isCameraOpen, setCameraOpen] = useState(false);
  const [note, setNote] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQrCodeModalOpen, setIsQrCodeModalOpen] = useState(false);
  const [isShoppingModalOpen, setIsShoppingModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpenCamera = () => {
    setQRCodeVisible(false);
    setCameraOpen(true);
  };

  const handleGenerateQRCode = () => {
    if (auth.currentUser) {
      setQRCodeVisible(true);
      setCameraOpen(false);
    } else {
      setError("L'utente non è autenticato");
    }
  };

  const handleScan = useCallback(
    async (data: any) => {
      if (data && data.text) {
        const uid = data.text;
        setCameraOpen(false);

        if (!auth.currentUser) {
          setError("L'utente non è autenticato");
          return;
        }

        try {
          const userDocRef = doc(db, "users", uid);
          await updateDoc(userDocRef, {
            complaints: arrayUnion({
              note: note,
              timestamp: new Date().toISOString(),
              byUserId: auth.currentUser.uid,
            }),
            complaintCount: increment(1),
          });

          setNote("");
          navigate(`/profile/${uid}`);
        } catch (error) {
          console.error("Errore durante l'aggiunta della lamentela:", error);
          setError("Errore durante l'aggiunta della lamentela.");
        }
      }
    },
    [note, navigate]
  );

  const handleError = (err: any) => {
    console.error(err);
    setError("Errore durante la scansione del QR code.");
  };

  useEffect(() => {
    if (error) {
      // Reset error after a short delay
      const timer = setTimeout(() => setError(null), 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <div className={styles.homepage}>
      {!(isQRCodeVisible || isCameraOpen) && (
        <img src="/logo.png" alt="logo" className={styles.logo} />
      )}
      <div className="flex align-items-center w-100 gap-1">
        {(isCameraOpen || isQRCodeVisible) && (
          <button
            className={styles.back}
            onClick={
              isQRCodeVisible
                ? () => setQRCodeVisible(false)
                : () => setCameraOpen(false)
            }
          >
            <Icon content="arrowLeft" />
          </button>
        )}
        <h2>Il lamentometro</h2>
        <div className="ml-auto">
          <button
            className={styles.infoButton}
            onClick={() => setIsModalOpen(true)}
          >
            <Icon content="info" />
          </button>
        </div>
      </div>

      {!(isQRCodeVisible || isCameraOpen) && (
        <div className={styles.buttons}>
          <button
            className={`${styles.button} ${styles.coral}`}
            onClick={handleGenerateQRCode}
          >
            <Icon content="qrCode" color="white" />
            Il mio QR code
          </button>
          <button
            className={`${styles.button} ${styles.emerald}`}
            onClick={handleOpenCamera}
          >
            <Icon content="plus" color="white" />
            Registra lamentela
          </button>
          <button
            className={`${styles.button} ${styles.midnight}`}
            onClick={() => navigate("/leaderboard")}
          >
            <Icon content="trophy" color="white" />
            Classifica
          </button>
        </div>
      )}

      {isQRCodeVisible && auth.currentUser && (
        <div className={styles.qrCodeContainer}>
          <h2>Il tuo QR code</h2>
          <QRCode value={auth.currentUser.uid} size={256} />
        </div>
      )}

      {isCameraOpen && (
        <div className={styles.cameraContainer}>
          <h2>Registra una lamentela</h2>
          <textarea
            className={styles.textarea}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Descrivi la lamentela"
          />
          <p>
            Ora scansiona il QR Code della persona che ha sollevato la lamentela
            per salvare
          </p>
          <Button onClick={() => setIsQrCodeModalOpen(true)} disabled={!note}>
            <div className="flex gap-1 align-items-center">
              <Icon content="qrCode" color="white" /> Scansiona
            </div>
          </Button>

          <Modal
            isOpen={isQrCodeModalOpen}
            onClose={() => setIsQrCodeModalOpen(false)}
          >
            <h2>Scansiona il QR Code</h2>
            <ScannerWrapper onScan={handleScan} onError={handleError} />
            {error && <p className={styles.error}>{error}</p>}
          </Modal>
        </div>
      )}

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2>Il Lamentometro: come funziona?</h2>
        <p>
          Quando un individuo presenta una lamentela, seleziona l'opzione
          "Registra una lamentela" e inserisce i dettagli pertinenti nell'area
          di testo. Successivamente, è necessario richiedere il QR Code della
          persona che ha sollevato la lamentela. Scansionando il QR Code, la
          lamentela verrà ufficialmente registrata nel sistema. Questa procedura
          di utilizzo del QR Code è progettata per garantire che le segnalazioni
          siano valide e autentiche, prevenendo l'inserimento di lamentele non
          giustificate. In altre parole, il sistema si basa su un accordo
          reciproco per assicurare la legittimità delle segnalazioni.
        </p>
      </Modal>

      {!isCameraOpen && !isQRCodeVisible && (
        <>
          <div className="flex align-items-center w-100">
            <h2>La lista della spesa</h2>
            <div className="ml-auto">
              <button
                className={styles.infoButton}
                onClick={() => setIsShoppingModalOpen(true)}
              >
                <Icon content="info" />
              </button>
            </div>
          </div>

          <button
            className={`${styles.button} ${styles.sun}`}
            onClick={() => navigate("/shopping-list")}
          >
            <Icon content="shoppingCart" color="white" />
            Aggiorna lista
          </button>

          <div className="flex align-items-center w-100 ">
            <h2>Luoghi</h2>
          </div>

          <button
            className={`${styles.button} ${styles.indigo}`}
            onClick={() => navigate("/map")}
          >
            <Icon content="map" color="white" />
            Apri mappa
          </button>
        </>
      )}

      <Modal
        isOpen={isShoppingModalOpen}
        onClose={() => setIsShoppingModalOpen(false)}
      >
        <h2>Lista della spesa</h2>
        <p>
          Una lista della spesa comune in cui tutti possono segnare cosa
          comprare, eliminare e spuntare gli articoli acquistati.
        </p>
      </Modal>
    </div>
  );
};

interface ScannerWrapperProps {
  onScan: (data: any) => void;
  onError: (error: any) => void;
}

const ScannerWrapper: React.FC<ScannerWrapperProps> = React.memo(
  ({ onScan, onError }) => {
    const [isCameraReady, setIsCameraReady] = useState(false);

    return (
      <div className={styles.qrReaderContainer}>
        {!isCameraReady && (
          <div className={styles.cameraInitializing}>
            <Spinner size="md" color="black" />
          </div>
        )}
        <QrReader
          onError={onError}
          onScan={onScan}
          style={{ width: "100%", height: "100%" }}
          constraints={{
            audio: false,
            video: { facingMode: "rear" },
          }}
          onLoad={() => setIsCameraReady(true)}
        />
      </div>
    );
  }
);
export default Homepage;
