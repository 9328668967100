// src/App.tsx
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Navbar from "./components/navbar/navbar";
import PrivateRoute from "./components/private-route/private-route";
import { auth } from "./firebase";
import { AddComplaint } from "./pages/complaint/add-complaint";
import { ComplaintsLeaderboard } from "./pages/complaints-leader-board/complaints-leader-board";
import Homepage from "./pages/homepage/homepage";
import Login from "./pages/login/login";
import { UserProfile } from "./pages/profile/profile";
import Register from "./pages/register/register";
import ShoppingList from "./pages/shopping-list/shopping-list";
import Map from "./pages/map/map";

const App: React.FC = () => {
  const [user] = useAuthState(auth);

  return (
    <Router>
      <div className="app">
        {user && <Navbar />}
        <main className="body">
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Homepage />
                </PrivateRoute>
              }
            />
            <Route
              path="/add-complaint/:uid"
              element={
                <PrivateRoute>
                  <AddComplaint />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile/:uid"
              element={
                <PrivateRoute>
                  <UserProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/leaderboard"
              element={
                <PrivateRoute>
                  <ComplaintsLeaderboard />
                </PrivateRoute>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route
              path="/register"
              element={
                <PrivateRoute>
                  <Register />
                </PrivateRoute>
              }
            />
            <Route
              path="/shopping-list"
              element={
                <PrivateRoute>
                  <ShoppingList />
                </PrivateRoute>
              }
            />
            <Route
              path="/map"
              element={
                <PrivateRoute>
                  <Map />
                </PrivateRoute>
              }
            />
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App;
