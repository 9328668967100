import React, { useEffect, useState } from "react";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import styles from "./profile.module.scss";
import Modal from "../../components/modal/modal";
import {
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import Button from "../../components/button/button";
import Input from "../../components/input/input";
import Header from "../../components/header/header";
import { Icon } from "../../components/icons";
import Spinner from "../../components/spinner/spinner";

interface Complaint {
  note: string;
  timestamp: number;
}

interface User {
  name: string;
  complaintCount: number;
  complaints?: Complaint[];
}

export const UserProfile: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const { uid } = useParams<{ uid: string }>();
  const [loggedInUser] = useAuthState(auth);
  const [isModalOpen, setIsModalOpen] = useState(false); // Stato per la modale di cambio password
  const [newPassword, setNewPassword] = useState(""); // Stato per la nuova password
  const [confirmPassword, setConfirmPassword] = useState(""); // Stato per la conferma password
  const [currentPassword, setCurrentPassword] = useState(""); // Stato per la password corrente
  const [error, setError] = useState<string | null>(null); // Stato per gli errori

  const navigate = useNavigate();

  useEffect(() => {
    if (uid) {
      const userRef = doc(db, "users", uid);

      const unsubscribe = onSnapshot(
        userRef,
        (doc) => {
          if (doc.exists()) {
            setUser(doc.data() as User);
          } else {
            setUser(null);
          }
        },
        (error) => {
          console.error("Errore durante il recupero dei dati utente:", error);
        }
      );

      return () => unsubscribe();
    }
  }, [uid]);

  const handleDelete = async (index: number) => {
    if (uid && user && user.complaints) {
      try {
        const updatedComplaints = [...user.complaints];
        updatedComplaints.splice(index, 1);

        const userRef = doc(db, "users", uid);
        await updateDoc(userRef, {
          complaints: updatedComplaints,
          complaintCount: updatedComplaints.length,
        });
      } catch (error) {
        console.error(
          "Errore durante la cancellazione della lamentela:",
          error
        );
      }
    }
  };

  const reauthenticate = async () => {
    if (loggedInUser?.email && currentPassword) {
      const credential = EmailAuthProvider.credential(
        loggedInUser.email,
        currentPassword
      );
      try {
        await reauthenticateWithCredential(loggedInUser, credential);
        return true;
      } catch (error) {
        console.error("Errore durante la reautenticazione:", error);
        setError("Password corrente errata. Riprova.");
        return false;
      }
    }
    return false;
  };

  const handlePasswordChange = async () => {
    if (loggedInUser && newPassword) {
      if (newPassword !== confirmPassword) {
        setError("Le password non corrispondono.");
        return;
      }

      const isReauthenticated = await reauthenticate();
      if (!isReauthenticated) return;

      try {
        await updatePassword(loggedInUser, newPassword);
        setNewPassword("");
        setConfirmPassword("");
        setCurrentPassword("");
        setIsModalOpen(false);
        setError(null); // Resetta l'errore dopo un aggiornamento riuscito
      } catch (error) {
        console.error("Errore durante l'aggiornamento della password:", error);
        // @ts-ignore
        setError(error.message);
      }
    }
  };

  const handleOpenPasswordModal = () => {
    if (uid === loggedInUser?.uid) {
      setIsModalOpen(true);
    }
  };

  const logout = () => {
    auth.signOut();
  };

  if (!user)
    return (
      <p className={styles.loading}>
        <Spinner color="black" size="xl" />
      </p>
    );

  return (
    <div className={styles.profileContainer}>
      <Header>
        <div className={styles.headerProfile}>
          <div className="flex align-items-center gap-1 w-100">
            {uid !== loggedInUser?.uid && (
              <button
                className={styles.back}
                onClick={() => navigate("/leaderboard")}
              >
                <Icon content="arrowLeft" />
              </button>
            )}
            <h2 className={styles.userName}>{user.name}</h2>
          </div>
          <p className={styles.complaintCount}>
            Numero di lamentele: {user.complaintCount}
          </p>
          {uid === loggedInUser?.uid && (
            <div className="flex gap-1 justify-content-center w-100">
              <Button className=" mt-1" onClick={handleOpenPasswordModal}>
                Cambia Password
              </Button>
              <Button className="mt-1" onClick={logout}>
                Logout
              </Button>
            </div>
          )}
        </div>
      </Header>

      <div
        className={`${styles.profileBody} ${
          uid === loggedInUser?.uid ? styles.mine : styles.notMine
        }`}
      >
        <ul className={styles.complaintList}>
          {user.complaints && user.complaints.length > 0 ? (
            user.complaints
              .sort(
                (a, b) =>
                  new Date(b.timestamp).getTime() -
                  new Date(a.timestamp).getTime()
              )
              .map((complaint, index) => (
                <li key={index} className={styles.complaintItem}>
                  <div className="flex flex-direction-column">
                    <p className={styles.complaintNote}>{complaint.note}</p>
                    <small className={styles.complaintDate}>
                      {new Date(complaint.timestamp).toLocaleString()}
                    </small>
                  </div>
                  {loggedInUser?.email === "davide.palluzzi@mailinator.com" && (
                    <Button
                      className={styles.deleteButton}
                      onClick={() => handleDelete(index)}
                    >
                      Cancella
                    </Button>
                  )}
                </li>
              ))
          ) : (
            <li className={styles.noComplaints}>Nessuna lamentela trovata.</li>
          )}
        </ul>
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2>Cambia Password</h2>
        <div className="flex flex-direction-column gap-1">
          <Input
            type="password"
            placeholder="Password corrente"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            className={styles.passwordInput}
          />
          <Input
            type="password"
            placeholder="Nuova password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className={styles.passwordInput}
          />
          <Input
            type="password"
            placeholder="Conferma password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className={styles.passwordInput}
          />
          <Button className={styles.saveButton} onClick={handlePasswordChange}>
            Salva
          </Button>
        </div>
        {error && <p className={styles.error}>{error}</p>}
      </Modal>
    </div>
  );
};
