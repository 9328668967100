import React, { useState, useEffect } from "react";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom"; // Importa useNavigate
import styles from "./login.module.scss";
import Input from "../../components/input/input";
import Button from "../../components/button/button";
import { useAuthState } from "react-firebase-hooks/auth";

const Login: React.FC = () => {
  const [user] = useAuthState(auth);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate(); // Inizializza useNavigate

  useEffect(() => {
    if (user) {
      navigate("/"); // Redirige alla home page se l'utente è loggato
    }
  }, [user, navigate]);

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setError(null);
    } catch (err) {
      setError("Errore durante il login. Verifica le credenziali.");
    }
  };

  if (user) {
    return <div>Sei già loggato</div>; // Messaggio temporaneo, sarà sostituito dalla redirezione
  }

  return (
    <div className={styles.login}>
      <img src="/logo.png" alt="logo" className={styles.logo} />
      <h5>Con comodo Lamentometro e altre funzionalità</h5>
      <h3>Accedi</h3>
      <label className="input-label">Email</label>
      <Input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
        className="mb-1"
      />
      <label className="input-label">Password</label>
      <Input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        className="mb-1"
      />
      {error && <p className={styles.error}>{error}</p>}
      <Button className="w-100" onClick={handleLogin}>
        Accedi
      </Button>
    </div>
  );
};

export default Login;
