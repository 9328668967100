import React from "react";

interface InputProps {
  type: string;
  value?: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  step?: string;
  pattern?: string;
  required?: boolean;
  minLength?: number;
  className?: string;
}

const Input: React.FC<InputProps> = ({
  type,
  value,
  onChange,
  placeholder,
  step,
  pattern,
  required,
  minLength,
  className,
}) => {
  return (
    <>
      <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        step={step}
        pattern={pattern}
        required={required}
        minLength={minLength}
        className={`input-field ${className}`}
      />
    </>
  );
};

export default Input;
