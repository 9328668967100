import { ReactComponent as ArrowCompare } from "./icon-arrow-compare.svg";
import { ReactComponent as ArrowDown } from "./icon-arrow-down.svg";
import { ReactComponent as ArrowUp } from "./icon-arrow-up.svg";
import { ReactComponent as ArrowLeft } from "./icon-arrow-left.svg";
import { ReactComponent as ArrowLeftDouble } from "./icon-arrow-left-double.svg";
import { ReactComponent as ArrowRight } from "./icon-arrow-right.svg";
import { ReactComponent as ArrowRightDouble } from "./icon-arrow-right-double.svg";
import { ReactComponent as SortList } from "./icon-sort-list.svg";
import { ReactComponent as Filter } from "./icon-filter.svg";
import { ReactComponent as Edit } from "./icon-edit.svg";
import { ReactComponent as Logout } from "./icon-logout.svg";
import { ReactComponent as Image } from "./icon-image.svg";
import { ReactComponent as Search } from "./icon-search.svg";
import { ReactComponent as Star } from "./icon-star.svg";
import { ReactComponent as StarSolid } from "./icon-star-solid.svg";
import { ReactComponent as Home } from "./icon-home.svg";
import { ReactComponent as Transactions } from "./icon-transactions.svg";
import { ReactComponent as Close } from "./icon-close.svg";
import { ReactComponent as Payments } from "./icon-payments.svg";
import { ReactComponent as Checked } from "./icon-checked.svg";
import { ReactComponent as Products } from "./icon-products.svg";
import { ReactComponent as Download } from "./icon-download.svg";
import { ReactComponent as Upload } from "./icon-upload.svg";
import { ReactComponent as Network } from "./icon-network.svg";
import { ReactComponent as Orders } from "./icon-orders.svg";
import { ReactComponent as Market } from "./icon-market.svg";
import { ReactComponent as Deadlines } from "./icon-deadlines.svg";
import { ReactComponent as Help } from "./icon-help.svg";
import { ReactComponent as Plus } from "./icon-plus.svg";
import { ReactComponent as ProductTypes } from "./icon-product-types.svg";
import { ReactComponent as Info } from "./icon-info.svg";
import { ReactComponent as SoundOn } from "./icon-sound-on.svg";
import { ReactComponent as SoundOff } from "./icon-sound-off.svg";
import { ReactComponent as Leaf } from "./icon-leaf.svg";
import { ReactComponent as ChevronRight } from "./icon-chevron-right.svg";
import { ReactComponent as ChevronLeft } from "./icon-chevron-left.svg";
import { ReactComponent as ChevronDown } from "./icon-chevron-down.svg";
import { ReactComponent as ShoppingCart } from "./icon-shopping-cart.svg";
import { ReactComponent as OpenEye } from "./icon-open-eye.svg";
import { ReactComponent as StaringEye } from "./icon-staring-eye.svg";
import { ReactComponent as RedArrow } from "./icon-arrow-red-icon.svg";
import { ReactComponent as GreenArrow } from "./icon-arrow-green-icon.svg";
import { ReactComponent as BlockChain } from "./blockchain.svg";
import { ReactComponent as Dollar } from "./icon-dollar.svg";
import { ReactComponent as Happy } from "./icon-happy.svg";
import { ReactComponent as Sad } from "./icon-sad.svg";
import { ReactComponent as List } from "./icon-list.svg";
import { ReactComponent as Pallet } from "./icon-pallet.svg";
import { ReactComponent as Container } from "./icon-container.svg";
import { ReactComponent as Tir } from "./icon-tir.svg";
import { ReactComponent as Settings } from "./icon-settings.svg";
import { ReactComponent as Trophy } from "./icon-trophy.svg";
import { ReactComponent as QrCode } from "./icon-qr-code.svg";
import { ReactComponent as Menu } from "./icon-menu.svg";
import { ReactComponent as Map } from "./icon-map.svg";
import { ReactComponent as Account } from "./icon-account.svg";
import { ReactComponent as Save } from "./icon-save.svg";
import { ReactComponent as ColorPicker } from "./icon-colorpicker.svg";
import { ReactComponent as Trash } from "./icon-trash.svg";
import React from "react";

const Icons = {
  arrowDown: ArrowDown,
  arrowUp: ArrowUp,
  sortList: SortList,
  filter: Filter,
  arrowCompare: ArrowCompare,
  arrowLeft: ArrowLeft,
  arrowLeftDouble: ArrowLeftDouble,
  arrowRight: ArrowRight,
  arrowRightDouble: ArrowRightDouble,
  edit: Edit,
  logout: Logout,
  image: Image,
  search: Search,
  star: Star,
  starSolid: StarSolid,
  home: Home,
  transactions: Transactions,
  close: Close,
  payments: Payments,
  checked: Checked,
  products: Products,
  download: Download,
  upload: Upload,
  network: Network,
  orders: Orders,
  market: Market,
  deadlines: Deadlines,
  help: Help,
  plus: Plus,
  productTypes: ProductTypes,
  info: Info,
  soundOn: SoundOn,
  soundOff: SoundOff,
  leaf: Leaf,
  chevronRight: ChevronRight,
  chevronLeft: ChevronLeft,
  chevronDown: ChevronDown,
  shoppingCart: ShoppingCart,
  openedEye: OpenEye,
  staringEye: StaringEye,
  redArrow: RedArrow,
  greenArrow: GreenArrow,
  blockchain: BlockChain,
  dollar: Dollar,
  sad: Sad,
  happy: Happy,
  list: List,
  container: Container,
  tir: Tir,
  pallet: Pallet,
  settings: Settings,
  trophy: Trophy,
  qrCode: QrCode,
  menu: Menu,
  map: Map,
  account: Account,
  save: Save,
  colorPicker: ColorPicker,
  trash: Trash,
};

export type TIcons = keyof typeof Icons;

type TIconProps = {
  content: TIcons;
  className?: string;
  color?: "white" | "black" | "grey" | "red" | "blue";
  size?: number;
};

export const Icon: React.FC<TIconProps> = ({
  content,
  className = "",
  color = "",
  size = 24,
}) => {
  const Svg = Icons[content];
  if (!Svg) return null; // Gestione del caso in cui l'icona non esiste

  return (
    <div
      className={`Icon ${className} ${color}`}
      style={{ width: size, height: size }}
    >
      <Svg width={size} height={size} />
    </div>
  );
};

export default Icon;
