import { useParams } from "react-router-dom";
import { useState } from "react";
import { auth, db } from "../../firebase";
import {
  doc,
  updateDoc,
  arrayUnion,
  increment,
  getDoc,
} from "firebase/firestore";

export const AddComplaint: React.FC = () => {
  const { uid } = useParams<{ uid: string }>();
  const [note, setNote] = useState("");

  const handleAddComplaint = async () => {
    if (uid) {
      const userRef = doc(db, "users", uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        await updateDoc(userRef, {
          complaints: arrayUnion({
            note: note,
            timestamp: new Date().toISOString(),
            byUserId: auth.currentUser?.uid,
          }),
          complaintCount: increment(1),
        });
      }
    }
    alert("Lamentela aggiunta con successo!");
  };

  return (
    <div>
      <h2>Aggiungi Lamentela</h2>
      <textarea
        value={note}
        onChange={(e) => setNote(e.target.value)}
        placeholder="Descrivi la lamentela"
      />
      <button onClick={handleAddComplaint}>Aggiungi</button>
    </div>
  );
};
