import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import Button from "../../components/button/button";
import Header from "../../components/header/header";
import Input from "../../components/input/input";
import Spinner from "../../components/spinner/spinner";
import { db } from "../../firebase";
import styles from "./shopping-list.module.scss";

interface ShoppingItem {
  id: string;
  name: string;
  purchased: boolean;
  createdAt: string;
}

const ShoppingList: React.FC = () => {
  const [items, setItems] = useState<ShoppingItem[]>([]);
  const [newItem, setNewItem] = useState<string>("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Crea la query ordinata
    const q = query(
      collection(db, "shoppingList"),
      orderBy("createdAt", "desc")
    );

    // Real-time listener
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newItems = querySnapshot.docs.map(
        (doc) => ({ id: doc.id, ...doc.data() } as ShoppingItem)
      );
      setItems(newItems);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const addItem = async () => {
    if (newItem.trim() !== "") {
      await addDoc(collection(db, "shoppingList"), {
        name: newItem,
        purchased: false,
        createdAt: serverTimestamp(), // Aggiunge il timestamp
      });
      setNewItem("");
    }
  };

  const deleteItem = async (id: string) => {
    await deleteDoc(doc(db, "shoppingList", id));
  };

  const togglePurchased = async (id: string, currentStatus: boolean) => {
    const itemRef = doc(db, "shoppingList", id);
    await updateDoc(itemRef, { purchased: !currentStatus });
  };

  if (loading) {
    return (
      <div className="flex justify-content-center mt-1">
        <Spinner color="black" size="xl" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Header>
        <div className="flex align-items-center gap-1">
          <h2>Lista della Spesa</h2>
        </div>
        <div className={styles.addContainer}>
          <Input
            type="text"
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
            placeholder="Nome dell'articolo"
            className={styles.addInput}
          />
          <Button onClick={addItem} className="ml-auto">
            Aggiungi
          </Button>
        </div>
      </Header>

      <div className={styles.body}>
        {items.length === 0 ? (
          <div className={styles.noItems}>
            <p>Nessun articolo trovato</p>
          </div>
        ) : (
          <ul className={styles.itemList}>
            {items.map((item) => (
              <li
                key={item.id}
                className={`${styles.item} ${
                  item.purchased ? styles.purchased : ""
                }`}
              >
                <span
                  className={`${styles.itemName} ${
                    item.purchased ? styles.purchased : ""
                  }`}
                >
                  {item.name}
                </span>
                <div className={styles.buttonGroup}>
                  <Button
                    onClick={() => togglePurchased(item.id, item.purchased)}
                  >
                    {item.purchased ? "Non comprato" : "Comprato"}
                  </Button>
                  <Button color="red" onClick={() => deleteItem(item.id)}>
                    Elimina
                  </Button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ShoppingList;
