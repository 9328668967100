import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./navbar.module.scss";
import { Icon } from "../icons";
import { auth } from "../../firebase";

const Navbar: React.FC = () => {
  const currentUser = auth.currentUser;

  if (!currentUser) {
    return null;
  }

  return (
    <nav className={styles.navbar}>
      <NavLink
        to="/"
        className={({ isActive }) =>
          `${styles.navItem} ${isActive ? styles.active : ""}`
        }
      >
        {({ isActive }) => (
          <>
            <Icon content="home" color={isActive ? "blue" : "grey"} />
            <span>Home</span>
          </>
        )}
      </NavLink>
      <NavLink
        to="/map"
        className={({ isActive }) =>
          `${styles.navItem} ${isActive ? styles.active : ""}`
        }
      >
        {({ isActive }) => (
          <>
            <Icon content="map" color={isActive ? "blue" : "grey"} />
            <span>Luoghi</span>
          </>
        )}
      </NavLink>
      <NavLink
        to="/shopping-list"
        className={({ isActive }) =>
          `${styles.navItem} ${isActive ? styles.active : ""}`
        }
      >
        {({ isActive }) => (
          <>
            <Icon content="shoppingCart" color={isActive ? "blue" : "grey"} />
            <span>Spesa</span>
          </>
        )}
      </NavLink>
      <NavLink
        to={`/profile/${currentUser.uid}`}
        className={({ isActive }) =>
          `${styles.navItem} ${isActive ? styles.active : ""}`
        }
      >
        {({ isActive }) => (
          <>
            <Icon content="account" color={isActive ? "blue" : "grey"} />
            <span>Profilo</span>
          </>
        )}
      </NavLink>
    </nav>
  );
};

export default Navbar;
