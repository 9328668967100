// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDPSyPy-XkXJx6X1nM0bj-nH7bfvG3eWz4",
  authDomain: "holiday-freedom.firebaseapp.com",
  projectId: "holiday-freedom",
  storageBucket: "holiday-freedom.appspot.com",
  messagingSenderId: "784795540124",
  appId: "1:784795540124:web:b294586673c31820f66b59",
  measurementId: "G-GLDV12ZFFR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
