import React, { useEffect, useState } from "react";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";
import styles from "./complaints-leader-board.module.scss";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../components/icons";
import Spinner from "../../components/spinner/spinner";
import Header from "../../components/header/header";

interface User {
  uid: string;
  name: string;
  complaintCount: number;
  rank?: number;
}

export const ComplaintsLeaderboard: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const usersCollection = collection(db, "users");
    const q = query(usersCollection, orderBy("complaintCount", "desc"));

    // Use onSnapshot to listen for real-time updates
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const usersList: User[] = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          usersList.push({
            uid: doc.id,
            name: data.name || "Anonymous",
            complaintCount: data.complaintCount || 0,
          });
        });

        // Determine rank with tie handling and alphabetical sorting
        const rankedUsers = rankUsers(usersList);
        setUsers(rankedUsers);
        setLoading(false);
      },
      (error) => {
        console.error("Errore durante il recupero degli utenti:", error);
      }
    );

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, []);

  const rankUsers = (usersList: User[]) => {
    // First, sort users by complaintCount descending and name ascending for tie-breaking
    const sortedUsers = usersList.sort((a, b) => {
      if (a.complaintCount !== b.complaintCount) {
        return b.complaintCount - a.complaintCount;
      }
      return a.name.localeCompare(b.name);
    });

    // Assign ranks
    const rankedUsers = [];
    let currentRank = 1;
    let lastComplaintCount = -1;
    let countAtRank = 0;

    for (let i = 0; i < sortedUsers.length; i++) {
      const user = sortedUsers[i];
      if (user.complaintCount !== lastComplaintCount) {
        currentRank += countAtRank;
        countAtRank = 0;
      }
      user.rank = currentRank;
      countAtRank++;
      lastComplaintCount = user.complaintCount;
      rankedUsers.push(user);
    }

    return rankedUsers;
  };

  if (loading) {
    return (
      <div className="flex justify-content-center mt-1">
        <Spinner color="black" size="xl" />
      </div>
    );
  }

  return (
    <div className={styles.leaderboardContainer}>
      <Header>
        <div className="flex align-items-center gap-1">
          <button className={styles.back} onClick={() => navigate("/")}>
            <Icon content="arrowLeft" />
          </button>
          <h2 className={styles.title}>Classifica Lamentele</h2>
        </div>
      </Header>
      <ol className={styles.leaderboardList}>
        <p>
          Clicca su un utente per visualizzare il suo profilo e le sue lamentele
        </p>
        {users.map((user) => (
          <li
            key={user.uid}
            onClick={() => navigate(`/profile/${user.uid}`)}
            className={`${styles.leaderboardItem} ${
              user.rank === 1
                ? styles.firstPlace
                : user.rank === 2
                ? styles.secondPlace
                : user.rank === 3
                ? styles.thirdPlace
                : styles.otherPlace
            }`}
          >
            <span className={styles.rank}>{user.rank}.</span>
            <span className={styles.userName}>{user.name}</span>
            <span className={styles.complaintCount}>
              {user.complaintCount}{" "}
            </span>
          </li>
        ))}
      </ol>
    </div>
  );
};
